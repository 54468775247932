import { SpinnerProps } from './Spinner.props';

export const Spinner = ({
  size = '104',
  color = '#FF708E',
}: SpinnerProps): JSX.Element => {
  return (
    <div className="relative">
      <div className="absolute right-1/2 transform translate-x-1/2 translate-y-1/2">
        <div
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderColor: color,
            borderTopColor: 'transparent',
          }}
          className={`animate-spin  border-t-transparent rounded-full border-[14px]`}
        ></div>
      </div>
    </div>
  );
};
