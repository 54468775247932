import Image from 'next/image';
import { useRouter } from 'next/router';
import { Spinner } from '@/components/index';
import { CoverRoverLogo } from '@/components/CoverRoverLogo/CoverRoverLogo';

const Fetching = (): JSX.Element => {
  const { pathname } = useRouter();

  const findingText = {
    firstLine: 'Finding the best prices',
    secondLine: 'in your area',
  };

  const fetchingText = {
    firstLine: 'Fetching the best insurance ',
    secondLine: 'coverage for Bobby',
  };

  const searchText = pathname === '/fetching' ? fetchingText : findingText;

  return (
    <div className="flex flex-col h-screen">
      <div className="flex justify-center mt-46px">
        <CoverRoverLogo width={240} height={50} styles="sm:ml-6 lg:ml-0" />
      </div>
      <div className="flex justify-center items-center h-full">
        <div className="flex flex-col md:text-32px text-26px font-roboto text-center">
          <div>
            <p>{searchText.firstLine}</p>
            <p>{searchText.secondLine}</p>
          </div>
          <div className="h-80">
            <Spinner color="#033159" />
          </div>
        </div>
        <div className="hidden md:block">
          <Image
            src="/images/fetching/dog_cuate.webp"
            alt="catAndDog"
            width={500}
            height={500}
          ></Image>
        </div>
      </div>
    </div>
  );
};

export default Fetching;
