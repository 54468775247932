import { useEffect } from 'react';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import TagManager from 'react-gtm-module';
import { useSessionStorage } from 'react-use';
import { withLayout } from '../layout/Layout';
import NextNProgress from 'nextjs-progressbar';
import '../styles/globals.css';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

const App = ({ Component, pageProps }: AppProps) => {
  const [sessionValue, setSessionValue] = useSessionStorage('url_object', '');
  const router = useRouter();

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID || '',
      preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
      auth: process.env.NEXT_PUBLIC_GTM_AUTH,
    });
    const urlHashMap = new Map();
    const routhAsPath = router.asPath.split('?')[1];
    const urlParams = new URLSearchParams(routhAsPath);

    if (routhAsPath && !sessionValue) {
      urlParams.forEach((value, key) => {
        urlHashMap.set(key, value);
      });
      const urlObject: { [key: string]: string } = {};
      urlParams.forEach((value, key) => {
        urlObject[key] = value;
      });
      setSessionValue(JSON.stringify(urlObject));
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(urlObject);
    }
  }, []);

  return (
    <>
      <NextNProgress
        color="#033159"
        options={{
          showSpinner: false,
          easing: 'ease',
        }}
      />
      <Component {...pageProps} />
    </>
  );
};

export default withLayout(App);
