import Image from 'next/image';
import { CheckmarkPet } from '../CheckmarkPet/CheckmarkPet';
import { AvatarProps } from './Avatar.props';

export const Avatar = ({ imgSrc, checked }: AvatarProps): JSX.Element => {
  const img = imgSrc ? imgSrc : 'dogAndCat';
  return (
    <div className="relative w-120px h-120px">
      <Image
        src={`/images/appForm/petsAvatar/${img}.webp`}
        alt={img}
        fill
        unoptimized
        className="w-120px h-120px bg-black rounded-full border-primary border-4"
      />
      {checked ? <CheckmarkPet /> : null}
    </div>
  );
};
