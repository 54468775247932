import Image from 'next/image';

export const Partners = ({
  title,
  subTitle,
  logos,
}: {
  title: string;
  subTitle: string;
  logos: Array<string>;
}) => {
  return (
    <div className="my-10">
      <div className="flex flex-col items-center">
        <div className="text-grey-lighten font-bold text-15px mb-1">
          {title}
        </div>
        <div
          id="lp_hero_trust_message"
          className="text-grey-lighten md:text-xl font-light"
        >
          {subTitle}
        </div>
      </div>
      <div className="flex md:justify-center justify-around md:flex-nowrap flex-wrap md:w-full w-5/6 mx-auto space-y-4 md:space-x-12 ">
        {logos?.map((logo: any, item: number) => (
          <Image
            src={logo?.url}
            alt={`partner_${item}`}
            width={123}
            height={40}
            className={`${logo.isMobile ? 'block' : 'hidden'} lg:block`}
          />
        ))}
      </div>
    </div>
  );
};
