import classNames from 'classnames';
import { Button } from '../Button/Button';
import { ModalProps } from './Modal.props';

export const Modal = ({
  children,
  acceptText,
  cancelText,
  acceptFunc,
  setIsModalOpen,
  hasOutsideClick = false,
  bgColor = '',
}: ModalProps) => {
  document.body.style.overflow = 'hidden';

  const closeModal = () => {
    document.body.style.overflow = 'auto';
    setIsModalOpen(false);
  };

  return (
    <div
      style={{ background: 'rgba(0,0,0,0.45)' }}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={hasOutsideClick ? closeModal : () => {}}
      className={classNames(
        'fixed inset-0 z-10 flex justify-center sm:items-center items-end',
        `bg-${bgColor || 'transparent'}`
      )}
    >
      <div className="bg-white w-full sm:w-max sm:rounded-xl rounded-t-xl rounded-b-none flex flex-col flex-wrap items-center p-8 delay-100">
        <span className="w-[320px] text-center ">{children}</span>
        <Button
          text={acceptText}
          styles="bg-red-grapefruit text-white rounded-3xl mt-4 py-2 px-16 "
          onClick={() => acceptFunc()}
          rightInnerIcon
          iconName="right_vector"
        />
        <Button
          text={cancelText}
          styles=" bg-none text-red-600 rounded-3xl mt-4"
          onClick={closeModal}
          leftInnerIcon
          iconName="cancel_vector"
        />
      </div>
    </div>
  );
};
