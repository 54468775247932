import eventEmitter from 'events';

class EventEmitter extends eventEmitter {}

const Events = new EventEmitter();

Events.setMaxListeners(Infinity);

export default Events;

// import { EventEmitter } from 'events';

// const Events = new EventEmitter();
// Events.setMaxListeners(Infinity);

// export default Events;
