import { useState, useEffect } from 'react';
import { Events } from '@/globalContext/index';

const useEmit = (listeners: { [key: string]: any }) => {
  const [entries] = useState(Object.entries(listeners));
  useEffect(() => {
    for (let i = 0; i < entries.length; i += 1) {
      Events.on(entries[i][0], entries[i][1]);
    }
    return () => {
      for (let i = 0; i < entries.length; i += 1) {
        Events.off(entries[i][0], entries[i][1]);
      }
    };
  }, [entries]);
};

export default useEmit;

// import { useEffect } from 'react';
// import { Events } from '@/globalContext/index';

// const useEmit = (listeners: { [key: string]: any }) => {
//   const entries = Object.entries(listeners);

//   useEffect(() => {
//     entries.forEach(([eventName, handler]) => Events.on(eventName, handler));

//     return () => entries.forEach(([eventName, handler]) => Events.off(eventName, handler));
//   }, [entries]);
// };

// export default useEmit;
