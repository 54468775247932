import { useEffect, useState } from 'react';
import { ProgressBarProps } from './ProgressBar.props';

export const ProgressBar = ({
  steps,
  title,
  bgColor = '#033159',
  currentStep,
  completelyFilled,
}: ProgressBarProps): JSX.Element => {
  const [width, setWidth] = useState(0);
  const filledWidth = width - (completelyFilled ? 10 : 0);

  useEffect(() => {
    setWidth((currentStep / steps) * 100);
  }, [steps, currentStep]);
  return (
    <div className="flex flex-col items-center">
      <div>
        <span className="font-bold">Step {currentStep}</span>
        <span className="font-light font-base">
          &nbsp;of {steps}
          {title}
        </span>
      </div>
      <div
        style={{ backgroundColor: 'rgba(217, 217, 217, 1)', width: '217px' }}
        className="w-full bg-gray-200 rounded-full h-2.5 align-center mt-2"
      >
        <div
          style={{
            backgroundColor: bgColor,
            width: `${filledWidth}%`,
          }}
          className=" h-2.5 rounded-full"
        ></div>
      </div>
    </div>
  );
};
