import { HtagProps } from './Htag.props';

export const Htag = ({
  id = '',
  tag,
  text,
  gtmId,
  styles,
  elementType,
  gtmParentEl,
}: HtagProps): JSX.Element => {
  switch (tag) {
    case 'h1':
      return (
        <h1
          gtm-id={gtmId}
          gtm-parent-el={gtmParentEl}
          id={id}
          className={`${elementType}, ${styles}`}
        >
          {text}
        </h1>
      );
    case 'h2':
      return (
        <h2
          gtm-id={gtmId}
          gtm-parent-el={gtmParentEl}
          id={id}
          className={`${elementType}, ${styles}`}
        >
          {text}
        </h2>
      );
    case 'h3':
      return (
        <h3
          gtm-id={gtmId}
          gtm-parent-el={gtmParentEl}
          id={id}
          className={`${elementType}, ${styles}`}
        >
          {text}
        </h3>
      );
    default:
      return <></>;
  }
};
