import Image from 'next/image';
import { CoverRoverLogoProps } from './CoverRoverLogo.props';

export const CoverRoverLogo = ({
  width = 247,
  height = 52,
  src = '/logos/cover_rover_logo.webp',
  alt = 'coverRoverLogo',
  styles = 'mb-6 md:mb-0',
  id = '',
}: CoverRoverLogoProps): JSX.Element => {
  return (
    <>
      <Image
        id={id}
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={styles}
        unoptimized
      />
    </>
  );
};
