import { Fragment, useState, useEffect } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { paths } from 'routes/index';
import { motion } from 'framer-motion';
import disableScroll from 'disable-scroll';
import { Button } from '@/components/index';
import { HeaderProps } from './Header.props';
import CloseMenu from '@/svg/icons/closeMenu.svg';
import BurgerMenu from '@/svg/icons/burgerMenu.svg';
import { CoverRoverLogo } from '@/components/CoverRoverLogo/CoverRoverLogo';
import { dataLayerHandler } from '@/utils/index';

type TLinkProps = {
  id: number;
  name: string;
  href: string;
  trackId: string;
};

export const Header = ({ className }: HeaderProps): JSX.Element => {
  const [navbar, setNavbar] = useState(false);
  disableScroll[navbar ? 'on' : 'off']();

  const toggleNavbar = () => {
    disableScroll['off']();
    setNavbar(!navbar);
  };

  useEffect(() => {
    return () => {
      return disableScroll['off'](), setNavbar(false);
    };
  }, []);

  const links: TLinkProps[] = [
    { id: 0, name: 'Home', href: '/', trackId: 'home_tab' },
    { id: 1, name: 'FAQ', href: '/faq', trackId: 'faq_tab' },
  ];

  const linkRenderer = links.map(({ id, name, href, trackId }) => (
    <Fragment key={id}>
      <li
        aria-label={name}
        className="flex items-center py-8 md:pl-6 pl-2 text-grey-light md:hover:bg-transparent hover:bg-pink-light"
      >
        <Link
          id={trackId}
          onClick={() => setNavbar(false)}
          href={href}
          aria-label={name}
          className="md:border-b-2 md:hover:border-primary mx-2 border-transparent"
        >
          {name}
        </Link>
      </li>
      <hr className="md:hidden block" />
    </Fragment>
  ));

  return (
    <div
      className={classNames(
        className,
        'w-full flex justify-center bg-white font-roboto'
      )}
    >
      <div className="w-1000px justify-between md:px-0 px-4 lg:max-w-7xl md:items-center md:flex">
        <div>
          <div className="flex items-center py-3 md:py-5 md:block">
            <div className="md:hidden">
              <button
                className="text-gray-700 outline-none focus:border-gray-400 focus:border"
                onClick={toggleNavbar}
                aria-label="Toggle navigation"
              >
                <BurgerMenu />
              </button>
            </div>
            <div className="mx-auto">
              <Link href={paths.home} aria-label="home-page">
                <CoverRoverLogo
                  src={`/atomicImages/${process.env.NEXT_PUBLIC_PROJECT}/layout/classicHeader/header_logo.webp`}
                  id="header_logo"
                  width={208}
                  height={44}
                  styles="sm:ml-6 lg:ml-0"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="">
          {!navbar && (
            <nav
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                navbar ? 'block' : 'hidden'
              }`}
            >
              <ul className="items-center justify-center px-40  md:flex md:space-x-8 md:space-y-0">
                {linkRenderer}
              </ul>
            </nav>
          )}
        </div>
        {navbar && (
          <div className="z-[100] absolute h-screen w-full md:hidden  top-0 left-0 drop-shadow-2xl 2">
            <motion.div
              transition={{ ease: 'circOut', duration: 0.5 }}
              initial={{ y: '0', x: '-100%', opacity: 0.3 }}
              animate={{ y: '0', x: '0px', opacity: 1 }}
            >
              <div className="bg-white h-screen p-2" style={{ width: '90%' }}>
                <div className="flex">
                  <button
                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                    onClick={() => setNavbar(false)}
                  >
                    <CloseMenu />
                  </button>
                  <div className="mx-auto">
                    <CoverRoverLogo
                      src={`/atomicImages/${process.env.NEXT_PUBLIC_PROJECT}/layout/classicHeader/header_logo.webp`}
                      id="header_logo"
                      width={171}
                      height={36}
                    />
                  </div>
                </div>
                <div className="mt-8">
                  <ul>
                    {linkRenderer}
                    <Link
                      onClick={() => setNavbar(!navbar)}
                      href={paths.quoteFirstPage}
                    >
                      <Button
                        id="hamburger_menu"
                        text="Get a free quote now"
                        styles="mt-8 bg-primary text-white w-full rounded-3xl text-lg py-2"
                        // onClick={() =>
                        //   dataLayerHandler(
                        //     'button_click',
                        //     'hamburger_menu_cta',
                        //     'Get a free quote now'
                        //   )
                        // }
                      />
                    </Link>
                  </ul>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};
