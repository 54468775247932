import Image from 'next/image';
import { ButtonProps } from './Button.props';

export const Button = ({
  id,
  type,
  styles,
  onClick,
  text = '',
  className,
  iconWidth = 8,
  iconName = '',
  leftInnerIcon,
  rightInnerIcon,
  iconHeight = 8,
  selected = false,
}: ButtonProps): JSX.Element => {
  const focused = `focus:font-bold focus:border-red-grapefruit focus:border-2 focus:bg-pink-focus`;
  return (
    <div
      className="w-full flex justify-center font-roboto"
      aria-label={text}
      role="button"
      onClick={onClick}
    >
      <button
        id={id}
        type={type || 'button'}
        className={`${styles} ${className} ${selected && focused}`}
        aria-label={text}
      >
        <div className="flex justify-center">
          {leftInnerIcon && (
            <Image
              src={`/icons/${iconName}.svg`}
              alt={iconName}
              width={iconWidth}
              height={iconHeight}
              className="mr-2"
            />
          )}
          {text}
          {rightInnerIcon && (
            <Image
              src={`/icons/${iconName}.svg`}
              alt={iconName}
              width={iconWidth}
              height={iconHeight}
              className="ml-2"
            />
          )}
        </div>
      </button>
    </div>
  );
};
