import { useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { paths } from 'routes/index';
import { useOnClickOutside } from '@/hooks/index';
import { CoverRoverLogo } from '@/components/CoverRoverLogo/CoverRoverLogo';

const PopUp = ({ closePopUp }: any) => {
  return (
    <div
      style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
      className="rounded-bl-[12px] rounded-br-[12px] bg-pink-white border border-primary absolute flex flex-col items-center pt-[22px] pr-[23px] pl-[22px] pb-[12px] text-[10px] top-[-250px] md:top-[-270px] left-[-30px] md:left-[-160px] lg:left-[-50px] xl:left-[50px]"
    >
      <span className="w-[240px]">
        CoverRover.com may earn a commission when a user completes an action
        using our links, which will however not affect the review but might
        affect the rankings. The latter is determined on the basis of product
        market fit and compensation received. The information contained in
        CoverRover.com should not be conceived as legal, financial or investment
        advice nor as an endorsement of any of the products or services
        referenced in CoverRover.com. As disclosed in the Terms and Conditions
        all information on CoverRover.com is subject to change. CoverRover.com
        does not contain an exhaustive list of all lending or insurance partners
        in each category.
      </span>
      <span
        onClick={() => closePopUp()}
        className="mt-[15px] cursor-pointer font-roboto text-[10px]"
      >
        CLOSE
      </span>
      <div
        style={{
          content: '',
          top: '97.6%',
          left: '50%',
        }}
        className="absolute ml-[-20px] md:ml-[70px] lg:ml-[60px] xl:ml-[-110px]"
      >
        <Image
          src="/triangleWithShadow.png"
          alt="as"
          width={41}
          height={29}
          className="mb-6"
        />
      </div>
    </div>
  );
};

export const Footer = (): JSX.Element => {
  const date = new Date();
  const year = date.getFullYear();
  const [popUp, togglePopUp] = useState(false);
  const ref = useRef(null);
  const closePopUp = () => togglePopUp(false);
  useOnClickOutside(ref, closePopUp);

  return (
    <footer className="bg-pink-white pt-8">
      <div
        id="footer_logo"
        className="flex items-center justify-center md:mb-9"
      >
        <CoverRoverLogo
          src={`/atomicImages/${process.env.NEXT_PUBLIC_PROJECT}/layout/footer/footer_logo.webp`}
          width={247}
          height={52}
        />
      </div>
      <nav className="mb-6">
        <div className="flex flex-col md:flex-row md:items-start items-center justify-center font-roboto">
          <div className="md:py-0 md:flex py-4 hover:bg-pink-light md:hover:bg-pink-white justify-center">
            <Link
              href={paths.faqs}
              id="footer_faq"
              className="text-grey-light md:border-b-2 md:hover:border-primary mx-8 border-transparent"
            >
              FAQ
            </Link>
            <div className="md:block hidden h-21px border-r-2 border-grey-dark mx-3"></div>
          </div>
          <hr className="md:hidden block h-px w-42px" />
          <div className="md:py-0 md:flex py-4 hover:bg-pink-light md:hover:bg-pink-white">
            <Link
              href={paths.terms}
              id="footer_terms"
              className="text-grey-light md:hover:border-primary mx-8 border-b-2 border-transparent"
            >
              Terms of use
            </Link>
            <div className="md:block hidden h-21px  border-r-2 border-grey-dark mx-3"></div>
          </div>
          <hr className="md:hidden block h-px w-42px" />
          <div className="md:py-0 md:flex py-4 hover:bg-pink-light md:hover:bg-pink-white">
            <Link
              href={paths.privacy}
              id="footer_privacy"
              className="text-grey-light md:hover:border-primary mx-8 border-b-2 border-transparent"
            >
              Privacy policy
            </Link>
            <div className="md:block hidden h-21px  border-r-2 border-grey-dark mx-3"></div>
          </div>
          <hr className="md:hidden block h-px w-42px" />
          <div className="md:py-0 md:flex py-4 hover:bg-pink-light md:hover:bg-pink-white relative">
            {popUp && (
              <div ref={ref}>
                <PopUp closePopUp={closePopUp} />
              </div>
            )}

            <span
              id="footer_disclouse"
              onClick={() => {
                return togglePopUp(true);
              }}
              className="cursor-pointer text-grey-light md:hover:border-primary mx-8 border-b-2 border-transparent"
            >
              Advertising Disclosure
            </span>
          </div>
        </div>
      </nav>
      <div className="bg-primary text-center text-sm text-white font-roboto py-2">
        CoverRover © {year} - All rights reserved
      </div>
    </footer>
  );
};
