import { SelectProps } from './Select.props';

export const Select = ({
  value,
  trakId,
  register,
  placeholder,
  options = [],
  defaultValue,
}: SelectProps) => {
  return (
    <div className="floating-form" id={trakId}>
      <div className="select-floating-label">
        <select {...register} className="floating-select">
          <option hidden value=""></option>
          {options.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </select>
        <label
          className={`select-label ${
            (value || defaultValue) && 'select-transformed-label'
          } `}
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
};
