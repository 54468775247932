import Image from 'next/image';
import React from 'react';
import { CheckmarkProps } from './CheckmarkPet.props';

export const CheckmarkPet = ({
  alt = 'checkmarkPet',
  src = '/images/appForm/petsAvatar/check.webp',
}: CheckmarkProps): JSX.Element => {
  return (
    <>
      <div className="w-120px h-120px absolute top-0  bg-red-500 rounded-full opacity-50" />
      <div className="w-120px h-120px top-0 absolute flex justify-center items-center flex-col pt-4">
        <Image src={src} alt={alt} width={57} height={39} unoptimized />
      </div>
    </>
  );
};
